
.cookie-consent-bar {
    /* position: fixed; */
    top: 0;
    left:0;
    width: 100%;
    max-width: 100%;
    z-index: 2000;
    overflow: hidden;

    background-color: #08D88C;
    color: white;
	border:none;
	-webkit-box-shadow: 0 14px 23px 0 rgba(0, 0, 0, 0.03);
	        box-shadow: 0 14px 23px 0 rgba(0, 0, 0, 0.03);
}

.cookie-consent-bar p {
	width: unset;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
    color: white;
	padding: 0 1em 0 0;
	margin: 0;
}

.cookie-consent-bar > div {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cookie-consent-bar a {
    color: white;
    text-decoration: underline;
}

.cookie-consent-bar button {
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 4px;
    background-color: #fff;
    border:none;
    height: 25px;

    font-size: 14px;
    text-align: center;
    color: #000;
}